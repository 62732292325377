/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { BreadCrumbs } from "./components/BreadCrumbs";

function SubHeader({ intl }) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);
  
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  // Start CustomBreadcrumbs
  const handleWithKnowledgeBreadcrumbs = () => {
    let aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);

    switch (currentKnowledge?.knowledge_type) {
      case "faq":
        addNewKnowledgeBreadcrumb(
          aside.breadcrumbs,
          breadcrumbMaker(
            "/knowledge-base/faqs",
            intl.formatMessage({
              id: "PAGE.KNOWLEDGE.FAQS",
            })
          )
        );
        break;
      case "video":
        addNewKnowledgeBreadcrumb(
          aside.breadcrumbs,
          breadcrumbMaker(
            "/knowledge-base/videos",
            intl.formatMessage({
              id: "PAGE.KNOWLEDGE.VIDEOS",
            })
          )
        );
        break;
      case "manual":
        addNewKnowledgeBreadcrumb(
          aside.breadcrumbs,
          breadcrumbMaker(
            "/knowledge-base/manuals",
            intl.formatMessage({
              id: "PAGE.KNOWLEDGE.MANUALS",
            })
          )
        );
        break;
      case "news":
        addNewKnowledgeBreadcrumb(
          aside.breadcrumbs,
          breadcrumbMaker(
            "/knowledge-base/updates",
            intl.formatMessage({ id: "PAGE.KNOWLEDGE.NEWS" })
          )
        );
        break;
      case "link":
        addNewKnowledgeBreadcrumb(
          aside.breadcrumbs,
          breadcrumbMaker(
            "/knowledge-base/links",
            intl.formatMessage({ id: "PAGE.KNOWLEDGE.LINKS" })
          )
        );
        break;
      case "newsletter":
        addNewKnowledgeBreadcrumb(
          aside.breadcrumbs,
          breadcrumbMaker(
            "/knowledge-base/newsletter",
            intl.formatMessage({
              id: "PAGE.KNOWLEDGE.NEWSLETTER.TITLE",
            })
          )
        );
        break;

      default:
        break;
    }
    subheader.setTitle(aside?.title);
  };

  const handleWithKnowledgeManagementBreadcrumbs = () => {
    let aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);

    subheader.setBreadcrumbs(aside.breadcrumbs);
    subheader.setTitle(aside?.title);
  };

  const changeElementPositionInArray = (arr, fromIndex, toIndex) => {
    const newArr = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, newArr);
    return newArr;
  };

  const handleWithLearningBreadcrumbs = () => {
    const IS_MY_TESTS_PAGE =
      currentCourse && courses?.tests && !courses?.attempt && !courses?.exam;
    const IS_MY_ATTEMPTS_PAGE =
      currentCourse && courses?.tests && courses?.attempt;
    const IS_MY_EXAM_PAGE = courses?.exam;

    const currentCourseBreadcrumb = breadcrumbMaker(
      `/learning/mycourses/${currentCourse?.id}/view/class`,
      currentCourse?.name
    );

    let aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs);

    if (IS_MY_TESTS_PAGE) {
      let breadcrumbs = [...aside.breadcrumbs, currentCourseBreadcrumb];
      const fromIndex = breadcrumbs.indexOf(currentCourseBreadcrumb);
      const toIndex = breadcrumbs.length - 2;

      changeElementPositionInArray(breadcrumbs, fromIndex, toIndex);
      subheader.setBreadcrumbs(breadcrumbs);
    }

    if (IS_MY_ATTEMPTS_PAGE) {
      let breadcrumbs = [
        ...aside.breadcrumbs,
        currentCourseBreadcrumb,
        breadcrumbMaker(
          `/learning/mycompletedcourses/test/${currentCourse?.id}/attempt/${courses?.attempt?.attemptId}`,
          intl.formatMessage({ id: "LEARNING.EXAM.ATTEMPT.TITLE" })
        ),
      ];

      subheader.setBreadcrumbs(breadcrumbs);
    }

    if (IS_MY_EXAM_PAGE) {
      let breadcrumbs = [
        ...aside.breadcrumbs,
        breadcrumbMaker(
          `/learning/mycourses/`,
          intl.formatMessage({ id: "LEARNING.MYCOURSES.TITLE.COURSES" })
        ),
        currentCourseBreadcrumb,
      ];

      subheader.setBreadcrumbs(breadcrumbs);
    }

    subheader.setTitle(aside?.title);
  };

  const handleWithCertificateBreadcrumbs = () => {
    let aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);

    if (certificate?.certificateUrl) {
      subheader.setBreadcrumbs([
        breadcrumbMaker(``, intl.formatMessage({ id: "PAGE.LEARNING" })),
        breadcrumbMaker(
          "/learning/mycompletedcourses",
          intl.formatMessage({
            id: "PAGE.SUB.LEARNING.MYCOURSES_COMPLETED",
          })
        ),
        breadcrumbMaker(
          `/learning/mycourses/${certificate?.courseId}/view/class`,
          certificate?.courseName
        ),
        breadcrumbMaker(
          `/learning/certificate/${certificate?.courseId}/`,
          intl.formatMessage({
            id: "PAGE.SUB.LEARNING.CERTIFICATE.VIEW",
          })
        ),
      ]);
    }

    subheader.setTitle(aside?.title);
  };
  const handleWithLearningTestBreadcrumbs = () => {
    let aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);

    if (test) {
      let newBreadcrumbs = [
        breadcrumbMaker(``, intl.formatMessage({ id: "PAGE.LEARNING" })),
        breadcrumbMaker(
          `/learning/management/courses`,
          intl.formatMessage({ id: "PAGE.SUB.LEARNING.MANAGEMENT" })
        ),
        breadcrumbMaker(
          `/learning/management/course/${test?.course_id}/exam`,
          `${test?.name}`
        ),
      ];

      subheader.setBreadcrumbs(newBreadcrumbs);
    }

    subheader.setTitle(aside?.title);
  };

  const { views, courses, certificate, test, contents } = useSelector(
    (state) => ({
      views: state.views,
      contents: state.contents,
      courses: state?.courses,
      certificate: state?.certificate,
      test: state?.tests?.testForEdit,
    }),
    shallowEqual
  );

  const currentKnowledge = views?.currentKnowledge;
  const currentCourse = courses?.currentCourse;

  const breadcrumbMaker = (pathname = "", title = "") => ({
    pathname: pathname,
    title: title,
  });

  const addNewKnowledgeBreadcrumb = (breadcrumbs = [], newBreadcrumb) => {
    
    breadcrumbs[2] = breadcrumbs[1];
    breadcrumbs[1] = newBreadcrumb;
    subheader.setBreadcrumbs(breadcrumbs);
  };

  useLayoutEffect(() => {
    handleWithKnowledgeBreadcrumbs();
  }, [views]);

  useLayoutEffect(() => {
    handleWithKnowledgeManagementBreadcrumbs();
  }, [contents]);

  useLayoutEffect(() => {
    handleWithLearningBreadcrumbs();
  }, [courses]);

  useLayoutEffect(() => {
    handleWithCertificateBreadcrumbs();
  }, [certificate]);

  useLayoutEffect(() => {
    handleWithLearningTestBreadcrumbs();
  }, [test]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4 ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5
              className="text-dark font-weight-bold my-2 mr-5"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "inline-block",
                margin: "auto",
              }}
            >
              <>{subheader.title}</>
            </h5>
          </div>

          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>
      </div>
    </div>
  );
}

export default injectIntl(SubHeader);
