/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import RenewPassword from "./RenewPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import * as auth from "../_redux/authRedux";
import { FormattedMessage } from "react-intl";
import api from '../../../services/api';
import { LanguageSelectorDropdown } from "../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown";

const { REACT_APP_BASE_URL } = process.env;

export function AuthPage() {
  const [logo, setLogo] = useState("");
  const [loginAsideImage, setLoginAsideImage] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [callbackUrl] = useState(window.location.pathname.includes('knowledge') ? `?callBackUrl=${window.location.pathname}` : '');

  api.get('/settings/appearance').then((response) => {
    const loginLogo = response.data.find((config) => (config.name === "login_logo"));
    if(loginLogo) {
      setLogo(`${REACT_APP_BASE_URL}/setting/${loginLogo.content}`);
    }

    const message = response.data.find((config) => (config.name === "welcome_message"));
    if(message) {
      setWelcomeMessage(message.content);
    }

    const asideImage = response.data.find((config) => (config.name === "aside-image"));
    if(asideImage) {
      setLoginAsideImage(`${REACT_APP_BASE_URL}/setting/${asideImage.content}`);
    }

    const systemFavico = response.data.find((config) => (config.name === "system_favico"));
    if(systemFavico) {
      const favicon = document.getElementById("system_favicon");
      favicon.href = `${REACT_APP_BASE_URL}/setting/${systemFavico.content}`;
      localStorage.setItem('system_favicon', `${REACT_APP_BASE_URL}/setting/${systemFavico.content}`);
    }

    const systemLogo = response.data.find((config) => (config.name === "system_logo"));
    if(systemLogo) {
      localStorage.setItem('system_logo', `${REACT_APP_BASE_URL}/setting/${systemLogo.content}`);
    }
  });

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-column flex-row-auto bgi-size-cover bgi-no-repeat p-0 p-lg-0"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-3.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            
              {/* start:: Aside header */}
              <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                <span className="text-center pt-lg-10 pb-10">
                  <img
                    alt="Logo"
                    className="max-h-120px"
                    src={logo}
                    onError={() => (toAbsoluteUrl("/media/logos/logo-letter-13.png"))}
                  />
                </span>
                <h3 className="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl">
                  {welcomeMessage}
                </h3>
              </div>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <div
                  className="aside-img bgi-no-repeat bgi-position-top"
                  style={{
                    backgroundSize: "630px",
                    minHeight: "550px !important",
                    flex: "1 auto",
                    backgroundImage: `url(${loginAsideImage})`
                  }}>
                </div>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              {/*
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  2020 Metronic
                </div>
                <div className="d-flex">
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div>
              </div>
              */}
              {/* end:: Aside footer for desktop */}
            
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            <div className="d-flex align-items-center position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <LanguageSelectorDropdown />
              <span className="font-weight-bold text-dark-50">
                <FormattedMessage id="AUTH.GENERAL.CREATE_ACCOUNT" />
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                <FormattedMessage id="AUTH.GENERAL.REGISTER_ACCOUNT" />
              </Link>
            </div>
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />

                <ContentRoute
                  path="/auth/password/reset"
                  component={RenewPassword}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to={`/auth/login${callbackUrl}`} />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; 2020 Metronic
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
