/* eslint-disable jsx-a11y/anchor-is-valid */
import { SettingsInputComponentSharp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFromLocalStorage } from "../../../../../app/utils/storage";

export function BreadCrumbs({ items }) {
  const currentKnowledge = useSelector((state) => state.views.currentKnowledge);

  const [_paths, setPaths] = useState(items)

  const mountPathOrigin = (path) => {
    console.log('currentKnowledge', currentKnowledge);
    let paths = items
    if(items.length >= 3 && currentKnowledge?.products?.length > 0) {
      currentKnowledge?.products.forEach((item) => paths.splice(1 + 1, 0, {pathname: `/knowledge-base/${path}/productId:${item.id}`, title: item.name}))
      if(currentKnowledge?.sub_products) {
        let currentProduct = currentKnowledge.products.find((item) => item.id === currentKnowledge.sub_products.system_product_id)
        paths.splice(1 + 2, 0, {
          pathname: `/knowledge-base/${path}/productId:${currentProduct.id}subProductId:${currentKnowledge.sub_products.id}`,
          title: currentKnowledge.sub_products.name,
        })
      }
      console.log('paths', paths);
      setPaths(paths)
    }else {
      setPaths(items)
    }
  }

  let currentMenu = getFromLocalStorage('currentMenu')
  useEffect(() => {
    console.log('currentMenu', currentMenu);
    
    mountPathOrigin(currentMenu)
  }, [items, currentKnowledge?.products, currentMenu])

  if (!items || !items.length) {
    return "";
  }


  return (
    <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
      <li className="breadcrumb-item">
        <Link to="/home">
          <i className="flaticon2-shelter text-muted icon-1x" />
        </Link>
      </li>
      {_paths.map((item, index) => (
        <li key={`bc${index}`} className="breadcrumb-item">
          <Link
            className="text-muted"
            to={{ pathname: item?.pathname }}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "inline-block",
              margin: "auto",
              maxWidth: 500,
            }}
          >
            {item?.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
