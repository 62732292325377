import { createSlice } from "@reduxjs/toolkit";

const initialAccessTokenState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  tokenName: "",
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const accessTokensSlice = createSlice({
  name: "access-tokens",
  initialState: initialAccessTokenState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    accessTokenFetched: (state, action) => {
      state.actionsLoading = false;
      state.assetForEdit = action.payload.asset;
      state.error = null;
    },
    accessTokensFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    accessTokenDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    accessTokenSaved: (state, action) => {
      state.tokenName = action.payload.token;
    },
  },
});
